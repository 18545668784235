import {S, SA, ACL, RCL, E, DC, ATTR } from '../utilities';

export default () => {
    const copys = SA('.copy');
    [...copys].forEach( c => {
        E(c, 'click', e => {
            const inp = S('input', c);
            const label = S('.label', c);
            inp.select();
            inp.setSelectionRange(0, 99999);
            document.execCommand('copy');
            ACL(label, 'show');
            setTimeout(() => {
                RCL(label, 'show');
            }, 2000);
        });
    });
}