export default () => {

    const $b = document.querySelector('body');

    const controller = () =>  {
        window.addEventListener('scroll', watcher);
    }

    const watcher = () => {
        $b.className = $b.className.replace(/\snav\_update/g, '');
        if (window.scrollY > 150) {
            $b.className = `${$b.className} nav_update`
        }
    }

    watcher();

    controller();
}
