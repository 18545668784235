import {S, SA, ACL, RCL, E, DC, ATTR } from '../utilities';
import Chartist from 'chartist';
import tooltip from 'chartist-plugin-tooltip';


export default () => {
    const charts = SA('section .chart');
    [...charts].forEach( chart => {
        new Chartist.Line(chart, {
            labels: JSON.parse(chart.getAttribute('data-labels')),
            series: JSON.parse(chart.getAttribute('data-series'))
        }, {
            showArea: true,
            fullWidth: true,
            axisY: {
                onlyInteger: true
            },
            plugins: [
                Chartist.plugins.tooltip()
            ]
        });
    });



}
