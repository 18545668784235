import axios from 'axios';
import {S, SA, ACL, RCL, E, DC, ATTR } from '../utilities';

const fetch = ({ type, value, id}, callback) => {
    axios.post('/api/field-info-finder', { type, value, id })
    .then(function (response) {
        callback(response.data);
    }).catch(function () {
        console.log('FAILURE!!');
    });
};

export default () => {
    const fields = SA('.url_maker');
    let timer;

    [...fields].forEach( field => {
        const inp = S('input', field);
        const type = field.getAttribute('data-forge');
        const id = field.getAttribute('data-id');
        const example = S('.example', field);
        E(inp, 'input', e => {
            RCL(field, 'error');
            RCL(field, 'loading');
            ACL(field, 'loading');
            clearTimeout(timer);
            example.innerHTML = e.target.value.toLowerCase();
            timer = setTimeout(() => {
                fetch({ type, value: e.target.value, id}, response => {

                    if (response.result) {
                        ACL(field, 'error');
                    }
                    RCL(field, 'loading');
                });
            }, 1000);
        });
    });
};
