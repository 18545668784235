import { DC, ATTR, TEXT, E } from '../../utilities';
import './imgcropper.mscss';
import Cropper from 'cropperjs';


const render = props => {
    const toolbox = DC('div', 'widget_toolbox');
    const header = DC('div', 'widget_toolbox_header');
    const toolbar = DC('div', 'widget_toolbox_footer');

    const rotate_l = DC('span', 'widget_toolbox_icon fas fa-undo');
    const rotate_r = DC('span', 'widget_toolbox_icon fas fa-redo');
    const flip_v = DC('span', 'widget_toolbox_icon fas fa-arrows-alt-v');
    const flip_h = DC('span', 'widget_toolbox_icon fas fa-arrows-alt-h');
    const bttn = DC('span', 'widget_toolbox_icon button');
    bttn.appendChild(TEXT('KLAAR'));

    const imgEl = DC('img', 'img');
    ATTR(imgEl, 'src', props.img_url);

    toolbar.appendChild(rotate_r);
    toolbar.appendChild(rotate_l);
    toolbar.appendChild(flip_v);
    toolbar.appendChild(flip_h);
    toolbar.appendChild(bttn);

    header.appendChild(imgEl);
    toolbox.appendChild(header);
    toolbox.appendChild(toolbar);

    return {
        toolbox,
        img: imgEl,
        rotate_r,
        rotate_l,
        flip_v,
        flip_h,
        bttn
    }
}

export default props => {
    const els = render(props);
    const cropper = new Cropper(els.img, {
        aspectRatio: 1,
        crop(e) {
            console.log(event.detail.x);
            console.log(event.detail.y);
            console.log(event.detail.width);
            console.log(event.detail.height);
            console.log(event.detail.rotate);
            console.log(event.detail.scaleX);
            console.log(event.detail.scaleY);
        }
    });
    E(els.rotate_r, 'click', e => {
        cropper.rotate(90);
    });
    E(els.rotate_l, 'click', e => {
        cropper.rotate(-90);
    });
    E(els.flip_v, 'click', e => {
        if (cropper.imageData.scaleY === -1) {
            cropper.scaleY(1);
        } else {
            cropper.scaleY(-1);
        }
    });
    E(els.flip_h, 'click', e => {
        if (cropper.imageData.scaleX === -1) {
            cropper.scaleX(1);
        } else {
            cropper.scaleX(-1);
        }
    });

    E(els.bttn, 'click', e => {
        props.onSave(cropper.getCroppedCanvas().toDataURL());
    });




    return els.toolbox;
}

