import {S, SA, ACL, RCL, E, DC, ATTR } from '../utilities';

export default () => {
    const bic = S('form .bic');
    const select = S('form .country select');

    E(select, 'change', e => {
        if (e.currentTarget.value !== 'nl') {
            RCL(bic, 'hidden');
        } else {
            ACL(bic, 'hidden');
        }
    });
}
