import { S, SA, DC, RCL, RCLA, ACL, E, RE, ATTR } from '../../utilities';
import './popup.mscss';

export default props =>  {
    const body = S('body');
    const exists = S('body .popup_overlay');
    const loader = DC('img', 'loader');
    const loaderCont = DC('div', 'popup_overlay_loader');
    const ovl = DC('div', 'popup_overlay popup_widget');
    const ovl_c = DC('div', 'popup_overlay_content');
    const ovl_x = DC('span', 'popup_overlay_close_btn close close_btn');
    const span_1 = DC('span');
    const span_2 = DC('span');
    ovl_x.appendChild(span_1);
    ovl_x.appendChild(span_2);
    ovl.appendChild(ovl_c);
    ovl.appendChild(ovl_x);
    ATTR(loader, 'src', '/assets/loader.gif')


    if (props.children) {
        ovl_c.appendChild(props.children);
    }

    loaderCont.appendChild(loader);

    E(ovl_x, 'click', e => {
        body.removeChild(ovl);
    });
    return {
        open() {
            body.appendChild(ovl);
        },
        close() {
            body.removeChild(ovl);
        },
        startLoading() {
            ovl_c.appendChild(loaderCont);
        },
        doneLoading() {
            ovl_c.removeChild(loaderCont);
        }
    }
}
