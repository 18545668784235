import {S, SA, CL, RCL, E, DC, ATTR } from '../utilities';

export default () => {
    const popTheVideo = () => {
        const popped = S('body .video_pop_up_widget');
        if (!popped) {
            const popUp = DC('div', 'video_pop_up_widget fullscreab popup_widget');
            const center = DC('div', 'video_pop_up_widget_content centered popup_widget_content');
            const video = DC('div', 'video_pop_up_widget_video');
            const videoHTML = S('.homepage_promo').innerHTML;
            const span = DC('span');
            const spanB = DC('span');
            const close = DC('a', 'video_pop_up_widget_close close close_btn');
            const icon = DC('i', 'fas fas-times');

            const body = S('body');
            ATTR(video, 'type', 'video/mp4');
            ATTR(video, 'src', '/assets/promo.mp4');
            ATTR(video, 'controls', 'controls')
            video.innerHTML = videoHTML;
            close.appendChild(span);
            close.appendChild(spanB);
            body.appendChild(popUp);
            popUp.appendChild(center);
            center.appendChild(close);
            center.appendChild(video);

            E(close, 'click', () => {
                body.removeChild(popUp);
                window.location.href = '/#';
            });
        }
    };

    if (window.location.hash.indexOf('#promo') > -1) {
        popTheVideo();
    }

    E(window, 'hashchange', e => window.location.hash.indexOf('#promo') > -1 ? popTheVideo() : null);
};
