import PopUp from './overlay';
import ImgCropper from './imgcropper';
import { DC, ATTR } from '../../utilities';
import axios from 'axios';

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export default () => {
    const file = document.querySelector('.file input');
    const img = document.querySelector('.file .img');
    const form = document.querySelector('form');
    const fid = document.querySelector('.upload_foto');

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const setBgImg = async e => {
        if (file.files && file.files[0]) {
            const fileURL = await toBase64(file.files[0]);
            img.setAttribute('style', `background-image: url(${fileURL})`);
            const popup = PopUp({ children: ImgCropper({
                img_url: fileURL,
                onSave: base64 => {
                    const block = base64.split(";");
                    const contentType = block[0].split(":")[1];
                    const realData = block[1].split(",")[1];
                    const blob = b64toBlob(realData, contentType);
                    const formDataToUpload = new FormData(form);
                    formDataToUpload.append('image', blob);
                    img.setAttribute('style', `background-image: url(${base64})`);

                    popup.startLoading();
                    axios.post('/api/save-file', formDataToUpload, {
                        headers: {
                          'Content-Type': 'multipart/form-data'
                        }
                    }).then(function (response) {
                        if (fid) {
                            ATTR(fid, 'value', response.data)
                        }
                        popup.doneLoading();
                        popup.close();
                    }).catch(function () {
                        console.log('FAILURE!!');
                        popup.doneLoading();
                    });
                }
            }) });
            popup.open();
        }
    }

    if (file) {
        file.addEventListener('change', e => {
            img.className = `${img.className} show`;
            setBgImg();
        });
    }

};
