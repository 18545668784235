
import fitvids from 'fitvids';
import File from './modules/File';
import NavWatcher from './modules/NavWatcher';
import PopVideos from './modules/PopVideos';
import Form from './modules/Form';
import Copy from './modules/Copy';
import FieldInfoFInder from './modules/FieldInfoFInder';
import Charts from './modules/Charts';

const init = () => {
    const mediaUploader = document.querySelector('.media_uploader');
    const formEditor = document.querySelector('form.form_data #editor');
    fitvids('.video');
    File();
    NavWatcher();
    PopVideos();
    Form();
    Copy();
    FieldInfoFInder();
    Charts();

    if (window.Quill && formEditor) {

        const form = document.querySelector('form.form_data');
        const txt = document.querySelector('form.form_data #editor_txt');
        const txt_doc = document.querySelector('form.form_data #editor');

        const quill = new Quill('#editor', {
            modules: { toolbar: true },
            theme: 'snow'
        });
        form.addEventListener('submit', function() {
            const editor = form.querySelector('.ql-editor');
            txt.value = editor.innerHTML;
        });
    }
};

window.addEventListener('load', init);
